import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { FaHouseUser, FaFileInvoiceDollar,FaFileExcel, FaClipboardList } from 'react-icons/fa';
import { BsFillFileCheckFill } from 'react-icons/bs';
import { AiOutlineFileAdd } from 'react-icons/ai';

import "./Invoice.css"

class Invoice extends Component{


    render(){
        return(
            <main id='sales-container' >
                

                <section className='sales-group-card' >

                    <Link to="/daftaripl" className='card-link' >
                        <AiOutlineFileAdd size={100} color='#ffffff'/>
                        <h3>Input Rate</h3>
                    </Link>
                    <Link to="/newinvoice" className='card-link' >
                        <FaFileInvoiceDollar size={90} color='#ffffff'/>
                        <h3>Buat Tagihan IPL IT</h3>
                    </Link>
                    <Link to="/cetakipl" className='card-link' >
                        <FaFileExcel size={90} color='#ffffff'/>
                        <h3>Cetak Tagihan</h3>
                    </Link>
                    <Link to="/unpaid" className='card-link' >
                        <BsFillFileCheckFill size={90} color='#ffffff'/>
                        <h3>Invoice Unpaid</h3>
                    </Link>
                    <Link to="/kwitansi" className='card-link' >
                        <BsFillFileCheckFill size={90} color='#ffffff'/>
                        <h3>Cetak Kwitansi</h3>
                    </Link>
                    <Link to="/ipllist" className='card-link' >
                        <FaClipboardList size={90} color='#ffffff'/>
                        <h3>Invoice Paid</h3>
                    </Link>

                </section>
            </main>
        )
    }


}

export default Invoice;