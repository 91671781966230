import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";

const CetakIPL = () => {
    const [payDate, setPayDate] = useState("")
    const [dataInvoice, setDataInvoice] = useState([])
    const [refrence, setRefrence] = useState("")

    useEffect(() => {
        getUnpaidInvoice()
    }, [])

    
    const generateRef = (e) => {


        let id = 0
        let tglInit = new Date()
        let tgl = tglInit.getMonth(e.created_at)+1
        let tglRoman = ""
        let year = tglInit.getFullYear()
  
        if(e.id_invoice < 10){
          id = "00"+e.id_invoice
        }else if(e.id_invoice >= 10 && e.id_invoice < 100){
          id = "0"+e.id_invoice
        }else{
          id = e.id_invoice
        }
  
  
        switch (tgl) {
          case 1:
            tglRoman = "I"
            break;
          case 2:
              tglRoman = "II"
            break;
          case 3:
      tglRoman = "III";
            break;
          case 4:
      tglRoman = "IV";
            break;
          case 5:
  tglRoman = "V";
            break;
          case 6:
    tglRoman = "VI";
            break;
          case 7:
    tglRoman = "VII";
            break;
          case 8:
          tglRoman = "VIII";
            break;
          case 9:
              tglRoman = "IX";
            break;
          case 10:
          tglRoman = "X";
            break;
          case 11:
            tglRoman = "XI";
            break;
          case 12:
            tglRoman = "XII";
            break;
      }
  
  
  
        console.log(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year);
        console.log(dataInvoice[0].id_invoice);
        const refrenceGen = id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year
        // setRefrence(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year)
        return refrenceGen
  
      }


    const getUnpaidInvoice = () => {
        axios.get(baseUrl+"getunpaidinvoice")
        .then(res => {
            console.log(res.data);
            setDataInvoice(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        setPayDate("")
        
      }
    


    const navigate = useNavigate()

    return(
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/invoice`)}} />
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    <section className="list-antrian" >
                    <h1>Cetak Tagihan  </h1>
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th>No.</th>
                            <th>Referensi</th>
                            <th>Nama</th>
                            <th>No.Hp</th>
                            <th>Email</th>
                            <th>No.Unit</th>
                            <th>IPL</th>
                            <th>Kuantitas IPL</th>
                            <th>Harga Internet</th>
                            <th>Kuantitas Internet</th>
                            <th>Diskon</th>
                            <th>Denda</th>
                            <th>Tanggal Invoice</th>
                            <th>Tanggal Jatuh Tempo</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dataInvoice.map((item, index) => {
                                return(
                                    <>
                                    
                                    <tr onClick={()=>{navigate("/detailcetak",{state:item})}}>
                                        <td>{index+1}</td>
                                        <td>{generateRef(item)}</td>
                                        <td>{item.nama}</td>
                                        <td>{item.no_hp1}</td>
                                        <td>{item.alamat_email}</td>
                                        <td>{item.no_unit}</td>
                                        <td>Rp.{item.ipl_invoice.toLocaleString("IN")}</td>
                                        <td>{item.kuantitas_ipl} Bulan</td>
                                        <td>Rp.{item.internet_invoice.toLocaleString("IN")}</td>
                                        <td>{item.kuantitas_internet} Bulan</td>
                                        <td>Rp.{item.potongan_ipl.toLocaleString("IN")}</td>
                                        <td>Rp.{item.penalty_invoice.toLocaleString("IN")}</td>
                                        <td>{item.date_full}</td>
                                        <td>{item.date_due}</td>
                                    </tr>

                                    </>
                                )
                            })}
                        </tbody>
                    </table>



                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Detail Tagihan</h1>
                  <div className = "profile-editForm" >
                    
                    <label>Tgl Bayar :  </label>
                    <input type="date" value={payDate} onChange={(e) => {setPayDate(e.target.value)}}   />
                    {/* <label>Kuantitas: </label>
                    <input type="number"  />
                    <label>Denda: </label>
                    <input type="number"  />
                    <label>Paket: </label>
                    <input type="text"/> */}
                    <div className = "form-button" > 
                    <button className = "profile-save btn " onClick={() => {console.log(payDate);}}  >Paid</button>
                    <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                    </div>
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
    )

}

export default CetakIPL;