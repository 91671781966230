import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import "./Sales.css"
import { FaHouseUser, FaUserPlus, FaHandshake, FaClipboardList } from 'react-icons/fa';
import axios from 'axios';

class Sales extends Component{
    // constructor(props){
    //     super(props);
    //     this.state = {
    //         data: {}
    //     }
    // }

    // componentDidMount(){
    //     axios.get('http://localhost:5000/getallproject')
    //     .then(res => {
    //         console.log(res.data[0]);
    //         this.setState({data: res.data[0]})
    //     }).catch(err => {
    //         console.log(err);
    //     })
    // }


    render(){
        return(
            <main id='sales-container' >
                
                
                <section className='sales-group-card' >

                    <Link to="/newcustomer" className='card-link' >
                        <FaUserPlus size={100} color='#ffffff'/>
                        <h3>Pendaftaran</h3>
                    </Link>
                    <Link to="/unit" className='card-link' >
                        <FaHouseUser size={100} color='#ffffff'/>
                        <h3>Unit</h3>
                    </Link>
                    <Link to="/bast" className='card-link' >
                        <FaHandshake size={100} color='#ffffff'/>
                        <h3>Bast</h3>
                    </Link>
                    <Link to="/allcustomer" className='card-link' >
                        <FaClipboardList size={90} color='#ffffff'/>
                        <h3>All Customer</h3>
                    </Link>

                </section>
            </main>
        )
    }


}

export default Sales;