import React, { useEffect, useState, useRef } from 'react';
import {
    FaArrowLeft,
    FaPrint
} from 'react-icons/fa'
import {useNavigate, useLocation} from 'react-router-dom'
import Swal from 'sweetalert2';
import {useReactToPrint} from 'react-to-print'

import { loadingHandler } from '../../components/Loading';
import Logo from '../../Assets/CHL-Logo.png'

import './DetailCetak.css'
import axios from 'axios';
import { baseUrl } from '../../Config/URL';

const DetailCetak = () => {
    const [dataDetail, setDataDetail] = useState("")
    const [penalty, setPenalty] = useState(0)
    const [diskon, setDiskon] = useState(0)
    const [total, setTotal] = useState(0)
    const [printDate, setPrintDate] = useState("")
    const [nominal_invoice, setNominalInvoice] = useState(0)
    const [addon_invoice, setAddonInvoice] = useState(0)
    const [potongan_ipl, setPotonganIpl] = useState(0)
    const [potongan_addon, setPotonganAddon] = useState(0)
    const [total_ipl, setTotalIpl] = useState(0)
    const [total_addon, setTotalAddon] = useState(0)
    const [yeariode_start, setYearPeriodeStart] = useState("")
    const [monthperiode_start, setMonthPeriodeStart] = useState("")
    const [monthperiode_end, setMonthPeriodeEnd] = useState("")
    const [yearperiode_end, setYearPeriodeEnd] = useState("")
    const [potongan_tambahan, setPotonganTambahan] = useState(0)
    const [subtotal, setSubtotal] = useState(0)
    const [ppn_invoice, setPpnInvoice] = useState(0)
    const [grandtotal_invoice, setGrandTotal] = useState(0)
    const [refrence, setRefrence] = useState("")

    const location = useLocation()
    const navigate = useNavigate()
    const componentRef = useRef()

    useEffect(() => {
        const {state} = location
        const dateFull = new Date()
        setDataDetail(state)
        setPenalty(state.penalty_invoice)
        setPotonganIpl(state.potongan_ipl)
        setTotal(state.total_ipl)
        setNominalInvoice(state.ipl_invoice)
        setAddonInvoice(state.internet_invoice)
        setPotonganAddon(state.potongan_internet)
        setPrintDate(dateFull.toDateString())
        setTotalIpl(state.total_ipl)
        setTotalAddon(state.total_internet)
        setPotonganTambahan(state.potongan_tambahan)
        setYearPeriodeEnd(state.periode_end.split('-')[0])
        setYearPeriodeStart(state.periode_start.split('-')[0])
        setSubtotal(state.subtotal)
        setPpnInvoice(state.ppn_invoice)
        setGrandTotal(state.grandtotal_invoice)
        // console.log(state.ipl_invoice.toLocaleString('IN'));
        // console.log(state.periode_start.split('-')[1]);
        bulanHandler(state.periode_start.split('-')[1], "start")
        bulanHandler(state.periode_end.split('-')[1], "end" )

        generateRef(state)

    },[])

    const generateRef = (e) => {


      let id = 0
      let tglInit = new Date()
      let tgl = tglInit.getMonth(dataDetail.created_at)+1
      let tglRoman = "asd"
      let year = tglInit.getFullYear()

      if(e.id_invoice < 10){
        id = "00"+e.id_invoice
      }else if(e.id_invoice >= 10 && e.id_invoice < 100){
        id = "0"+e.id_invoice
      }else{
        id = e.id_invoice
      }


      switch (tgl) {
        case 1:
          tglRoman = "I"
          break;
        case 2:
            tglRoman = "II"
          break;
        case 3:
    tglRoman = "III";
          break;
        case 4:
    tglRoman = "IV";
          break;
        case 5:
tglRoman = "V";
          break;
        case 6:
  tglRoman = "VI";
          break;
        case 7:
  tglRoman = "VII";
          break;
        case 8:
        tglRoman = "VIII";
          break;
        case 9:
            tglRoman = "IX";
          break;
        case 10:
        tglRoman = "X";
          break;
        case 11:
          tglRoman = "XI";
          break;
        case 12:
          tglRoman = "XII";
          break;
    }



      // console.log(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year);
      setRefrence(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year)

    }

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "INVOICE Mazenta_"+dataDetail.no_unit+"_"+dataDetail.date_full+"_"+dataDetail.nama,
        onAfterPrint: () => {
            Swal.fire({
                title: 'Success',
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
        }
    })
    
    const nominalQty = dataDetail.ipl_invoice*dataDetail.kuantitas_ipl
    const addOnQty = dataDetail.internet_invoice*dataDetail.kuantitas_internet
    // const penalty = dataDetail.penalty_invoice
    // const diskon = dataDetail.potongan_invoice
    // const total = dataDetail.total_invoice

    const bulanHandler = (e, cond) => {

        if(cond === "start"){
            
            switch (e) {
                case "01":
                  setMonthPeriodeStart("Januari");
                  break;
                case "02":
                    setMonthPeriodeStart("Februari");
                  break;
                case "03":
                    setMonthPeriodeStart("Maret");
                  break;
                case "04":
                    setMonthPeriodeStart("April");
                  break;
                case "05":
                    setMonthPeriodeStart("Mei");
                  break;
                case "06":
                    setMonthPeriodeStart("Juni");
                  break;
                case "07":
                    setMonthPeriodeStart("Juli");
                  break;
                case "08":
                    setMonthPeriodeStart("Agustus");
                  break;
                case "09":
                    setMonthPeriodeStart("September");
                  break;
                case "10":
                    setMonthPeriodeStart("Oktober");
                  break;
                case "11":
                    setMonthPeriodeStart("November");
                  break;
                case "12":
                    setMonthPeriodeStart("Desember");
                  break;
            }

        }else if(cond === "end"){
            switch (e) {
                case "01":
                  setMonthPeriodeEnd("Januari");
                  break;
                case "02":
                    setMonthPeriodeEnd("Februari");
                  break;
                case "03":
                    setMonthPeriodeEnd("Maret");
                  break;
                case "04":
                    setMonthPeriodeEnd("April");
                  break;
                case "05":
                    setMonthPeriodeEnd("Mei");
                  break;
                case "06":
                    setMonthPeriodeEnd("Juni");
                  break;
                case "07":
                    setMonthPeriodeEnd("Juli");
                  break;
                case "08":
                    setMonthPeriodeEnd("Agustus");
                  break;
                case "09":
                    setMonthPeriodeEnd("September");
                  break;
                case "10":
                    setMonthPeriodeEnd("Oktober");
                  break;
                case "11":
                    setMonthPeriodeEnd("November");
                  break;
                case "12":
                    setMonthPeriodeEnd("Desember");
                  break;
            }
        }

        

    }
    


    return (
        <main id='newcustomer-container' >

            
            <form>
            <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/cetakipl")}} />
            
            <div className='cetak-button-container' onClick={printHandler} >
                <FaPrint className='cetak-button' size={30} onClick={printHandler}/>
                <text>Print</text>
            </div>
            {/* <text className='cetak-button' onClick={printHandler} >Print</text> */}
            <section ref={componentRef} className='cetak' id='cetak-container'>

                <h1>Invoice</h1>
                
                <div className='cetak-head' >

                    <div className='cetak-logo' >
                        <img src={Logo} alt='logo-chl' />
                    </div>

                    <div className='cetak-refrensi' >
                            <p>Referensi : {refrence} </p>
                            <p>Tanggal   : <text>{dataDetail.date_full}</text> </p>
                            <p>Jatuh Tempo : <text>{dataDetail.date_due}</text></p>
                    </div>

                </div>

                <div className='info-customer' >

                <div className='cetak-infoperusahaan' >
                            <h3>Info Perusahaan:</h3>
                            <h4>Mazenta Residence</h4>
                            <p>Management Mazenta Residence</p>
                            <p>Jl. Bakti Karya Raya, Sawah Baru, Kec. Ciputat, Tangerang , Banten 15413</p>
                            <p>Telp: +62 21 2222 0080</p>
                            <p>Fax : +62 21 2222 0081</p>
                </div>

                    
                    <div className='detail-info' >
                    <h3>Tagihan Untuk:</h3>
                        <h4>Bapak/Ibu. {dataDetail.nama}</h4>
                        <p>Kluster: Mazenta Bintaro </p>
                        <p>Unit: {dataDetail.no_unit}</p>
                        <p>Telp: {dataDetail.no_hp1}</p>
                        <p>Email: {dataDetail.alamat_email}</p>
                    </div>

                </div>



                <div className='paragraph' >

                    <p>Kepada Yth, Bapak/Ibu</p>
                    <p>Terima kasih kami ucapkan kepada Bapak/Ibu sebagai Penghuni dari Perumahan Mazenta Residence yang berlokasi dikawasan yang telah memilih Mazenta Residence sebagai salah satu tempat tinggal.
Bersama ini kami kirimkan Invoice tagihan dengan rincian sebagai berikut :</p>

                </div>
                
                <div className='header-desc' >

                    <text className='no'>No.</text>
                    <text className='keterangan'>Keterangan</text>
                    <text>Total</text>
                    <text>% Diskon</text>
                    <text>Total Diskon</text>
                    <text>Total Tagihan</text>

                </div>

                <div className='body-desc' >

                    <text className='no'>1</text>
                    <text className='keterangan'><p>Iuran Pemeliharaan Lingkungan(IPL)</p>
                    <h5>Rp.{nominal_invoice.toLocaleString('IN')} x {dataDetail.kuantitas_ipl} Bulan </h5>
                    <h5>Periode IPL dari {monthperiode_start} {yeariode_start} s/d {monthperiode_end} {yearperiode_end} </h5>
                    </text>
                    <text>Rp.{nominalQty.toLocaleString('IN')}</text>
                    <text>{dataDetail.diskonipl_persen}%</text>
                    <text>Rp.({potongan_ipl.toLocaleString('IN')})</text>
                    <text>Rp.{total_ipl.toLocaleString('IN')}</text>

                </div>

                <div className='body-desc' >

                    <text className='no'>2</text>
                    <text className='keterangan'><p>Internet Speed {dataDetail.speed_internet} Mbps</p>
                            <h5>Rp.{addon_invoice.toLocaleString('IN')} x {dataDetail.kuantitas_internet} Bulan </h5>
                            
                            </text>
                    <text>Rp.{addOnQty.toLocaleString('IN')}</text>
                    <text>{dataDetail.diskonaddon_persen}%</text>
                    <text>Rp.({potongan_addon.toLocaleString('IN')})</text>
                    <text>Rp.{total_addon.toLocaleString('IN')}</text>

                </div>


               

                <div className='total' >

                    {/* <div className='null' >
                        
                    </div> */}
                    
                    <div className='desc-total' >
                        <h3>Diskon Tambahan: </h3>
                        <h3>Sub Total :</h3>
                        <h3>PPN :</h3>
                        <h3>Denda :</h3>
                        <h3 className = 'grand' >GRAND TOTAL</h3>
                    </div>

                    <div className='total-nominal' >
                        <h3>Rp.<span>{potongan_tambahan.toLocaleString('IN')}</span></h3>
                        <h3>Rp.<span>{subtotal.toLocaleString('IN')}</span></h3>
                        <h3>Rp.<span>{ppn_invoice.toLocaleString('IN')}</span></h3>
                        <h3>Rp.<span>{penalty.toLocaleString('IN')}</span></h3>
                        <h3 className='grand' >Rp.<span>{grandtotal_invoice.toLocaleString('IN')}</span></h3>
                    </div>

                </div>

                <div className='note' >
                    <p>Untuk administrasi, kami mohon agar melakukan pembayaran sesuai Invoice yang telah kami sampaikan melalui:</p>
                    <h4>Rekening PT. GRIYA GARDENIA INDAH</h4>

                    <div className='bank-container'>
                        <div className='bank-detail'>
                            <p>Bank</p>
                            <p>No. Virtual Account</p>
                            <p>Cabang</p>
                        </div>
                        <div className='bank-fill' >
                            <p>: Bank Mandiri</p>
                            <p>: {dataDetail.no_va}</p>
                            <p>: KCU KISAMAUN</p>
                        </div>
                    </div>
                    
                    
                    
                </div>

                <div className='sign' >
                    <ul>
                        <li>Tagihan IPL = Luas (M2) x Rp 7.000</li>
                        <li>Denda Keterlambatan akan dikenakan setelah Tanggal Jatuh Tempo</li>
                        <li>Pembayaran melalui Virtual Account yang tertera pada Invoice.</li>
                        <li>Mohon Bukti Pembayaran Dikirimkan Via email tenant.relation@mazenta.id atau melalui No WhatShapp tenant relation kami di +(62) 812 9181 0348</li>
                        <li>Kwitansi pembayaran dapat diambil di kantor Management Mazenta Residence.</li>
                        <li>Invoice ini di cetak oleh system dan tidak membutuhkan tanda tangan</li>
                    </ul>
                </div>

            </section>
            
        </form>

            
        </main>
    );
};

export default DetailCetak ;