import React, { useEffect, useState } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import CurrencyInput from 'react-currency-input-field';
import axios from "axios";
import { baseUrl } from "../../Config/URL";
import { loadingHandler } from "../../components/Loading";
import Swal from "sweetalert2";

import './NewInvoice.css'

const NewInvoice = () => {
    const [custData, setCustData] = useState("")
    const [price, setPrice] = useState("")
    const [qty, setQty] = useState(1)
    const [qtyAddon, setQtyAddon] = useState(1)
    const [penalty, setPenalty] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [discountPlus, setDiscountPlus] = useState(0)
    const [discountAddon, setDiscountAddon] = useState(0)
    const [addOn, setAddOn] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [iplActive, setIplActive] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [ppnInvoice, setPpnInvoice] = useState(0)
    const [tgl_jatuhtempo, setTglJatuhtempo] = useState("")

    const navigate = useNavigate()
        
    useEffect(() => {
        getIplActiveHandler()
    },[])

    useEffect(() => {
        loadingHandler(isLoading)
    })

    const getIplActiveHandler = () => {
        axios.get(baseUrl+'getiplactive')
        .then(res => {
            console.log(res.data);
            setIplActive(res.data)
        }).catch(err => {
            // console.log(err);
        })
    }

    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        setCustData(e)
        
      }
      
    const  closeModalHandler = () => {
        setQty(1)
        setPenalty(0)
        setCustData("")
        setDiscount(0)
        setDiscountPlus(0)
        setQtyAddon(1)
        setTglJatuhtempo("")
        console.log(qty);
        document.getElementById("profile-modal").style.display = "none"
        
      }

      
    const saveNewInvoice = () => {
        setIsLoading(true)
        // setIsLoading(true)
        // console.log(custData.nama);
        // console.log(custData.nominal);
        // console.log(custData.id_customer);
        // console.log(qty);
        // console.log(penalty);
        // console.log(Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discount/100))+Number(penalty)-Number(discountPlus)+Number(addOn*qtyAddon)-Number(addOn*qtyAddon*Number(discountAddon/100)));

        const ipl_invoice = custData.nominal
        const kuantitas_ipl = qty
        const kuantitas_internet = qtyAddon
        const internet_invoice = custData.tarif_internet
        const penalty_invoice = penalty
        const diskonipl_persen = discount
        const diskonaddon_persen = discountAddon
        const potongan_ipl = Math.floor(Number(ipl_invoice*qty)*Number(diskonipl_persen/100))
        const potongan_tambahan = discountPlus
        const potongan_internet = Math.floor(Number(custData.tarif_internet*qtyAddon)*Number(diskonaddon_persen/100))
        const total_ipl = Math.floor(Number(ipl_invoice*kuantitas_ipl)-potongan_ipl)
        const total_internet = Math.floor(Number(internet_invoice*kuantitas_internet)-potongan_internet)
        const subtotal = Number(total_internet+total_ipl-potongan_tambahan)
        const id_customer = custData.id_customer
        const id_ipl = custData.id_ipl
        const ppn_invoice = Math.floor(Number(subtotal*ppnInvoice))
        const grandtotal_invoice = Number(subtotal+ppn_invoice+penalty)
        const speed_internet = custData.speed_internet
        
        
        

        console.log("potonganIPL__"+potongan_ipl);
        console.log("Prtonganaddon"+potongan_internet);
        console.log("totalIPL__"+total_ipl);
        console.log("totaladdon_"+total_internet);
        console.log("subtotal"+subtotal);
        console.log("ppn__"+ppn_invoice);
        console.log("penalty__"+penalty_invoice);
        console.log("grandtotal__"+grandtotal_invoice);
        console.log("nominalInvoice__"+ipl_invoice);
        console.log("kuantitasipl__"+kuantitas_ipl);
        console.log("kuantiasaddon__"+kuantitas_internet);
        console.log("addoninvoice__"+internet_invoice);
        console.log("diskonIPlper__"+diskonipl_persen);
        console.log("diskonaddonpres__"+diskonaddon_persen);
        console.log("potongantambahan__"+potongan_tambahan);
        console.log("idcustomer__"+id_customer);
        console.log("idipl__"+id_ipl);
        console.log("ppn__"+ppn_invoice);

        // console.log(total_ipl);
        // console.log(total_add0n);

        axios.post(baseUrl+"savenewinvoice", {
            ipl_invoice,
            kuantitas_ipl,
            penalty_invoice,
            potongan_ipl,
            total_ipl,
            id_customer,
            id_ipl,
            internet_invoice,
            kuantitas_internet,
            potongan_internet,
            potongan_tambahan,
            total_internet,
            diskonaddon_persen,
            diskonipl_persen,
            subtotal, 
            grandtotal_invoice,
            ppn_invoice,
            tgl_jatuhtempo,
            speed_internet
        }).then(res => {
            console.log(res.data);
            setIsLoading(false)
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)
                    closeModalHandler()
                }
              })
        }).catch(err => {
            setIsLoading(false)
            console.log(err.response.data.msg);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)

                }
              })
        })

    }

    const checkNum = (e, fun) => {
        // console.log(e);
        if(e === undefined || e <= 0){
            fun(0)
        }else{
            fun(parseInt(e))
        }
    }

    const valuePPnHandler = () => {
        const subTotal = Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discount/100))-Number(discountPlus)+Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*qtyAddon*Number(discountAddon/100))

        return Math.floor(subTotal*Number(ppnInvoice))
    }

    const checkPpnHandler = (e) => {
        if(e){
            setPpnInvoice(11/100)
        }else{
            setPpnInvoice(0)
        }
    }

    const grandTotalHandler = () => {
        const subTotal = Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discount/100))-Number(discountPlus)+Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*qtyAddon*Number(discountAddon/100))
        const afterPPN = subTotal*ppnInvoice
         return Math.floor(subTotal+afterPPN+penalty)


    }

    console.log(custData);

    return(
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/invoice`)}} />
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    <section className="list-antrian" >
                    <h1>Buat Tagihan Baru</h1>
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th>No.</th>
                            <th>No. Unit</th>
                            <th>Nama</th>
                            <th>IPL Rate</th>
                            <th>Periode Awal</th>
                            <th>Periode Awal</th>
                            <th>Internet Rate</th>
                            <th>Periode Awal Internet</th>
                            <th>Periode Akhir internet</th>
                            <th>Email</th>
                            <th>No. Hp</th>
                            </tr>
                        </thead>
                        <tbody>

                            {iplActive.map((item, index) => {
                                return(
                                    <tr onClick={() => {openModalHandler(item)}}>
                                        <td>{index+1}</td>
                                        <td>{item.no_unit}</td>
                                        <td>{item.nama}</td>
                                        <td>Rp.{item.nominal.toLocaleString("IN")}</td>
                                        <td>{item.periode_start}</td>
                                        <td>{item.periode_end}</td>
                                        <td>{item.tarif_internet.toLocaleString('IN')}</td>
                                        <td>{item.periodestart_internet}</td>
                                        <td>{item.periodeend_internet}</td>
                                        <td>{item.alamat_email}</td>
                                        <td>{item.no_hp1}</td>
                                    </tr>
                                )
                            })}

                            
                        </tbody>
                    </table>



                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Detail Tagihan Untuk:</h1>
                  <h1>{custData.nama}</h1>
                  <div className = "profile-editForm" >
                    
                    <label>Harga :  </label>
                    <CurrencyInput
                        disabled    
                        id="input-example"
                        name="input-name"
                        placeholder="0"
                        decimalSeparator="."
                        value={custData.nominal}
                        />
                    {/* <input type="number"  value={price} onChange={(e) => {setPrice(parseInt(e.target.value))}}  /> */}
                    <label>Kuantitas: </label>
                    <CurrencyInput
                        id="input-example"
                        name="input-name"
                        placeholder={qty}
                        value={qty}
                        onValueChange={(e) => {checkNum(e, (setQty))}}
                        />
                        
                    
                        
                    <label>Diskon IPL: </label>
                    <CurrencyInput
                        id="input-example"
                        name="input-name"
                        decimalSeparator="."
                        placeholder={0}
                        value={discount}
                        onValueChange={(e) => {checkNum(e, (setDiscount))}}
                        />
                    
                        
                    {/* <input type="number" value={penalty} onChange={(e) => {setPenalty(parseInt(e.target.value))}} /> */}
                    {/* <label>Paket: </label>
                    <input type="text"/> */}
                    <label>Total IPL: </label>
                    <CurrencyInput
                        disabled    
                        id="input-example"
                        name="input-name"
                        placeholder={0}
                        decimalSeparator="."
                        defaultValue={0}
                        value={Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discount/100))}
                        />
                    <h3>Add On:</h3>
                    <label>Internet:</label>
                    <CurrencyInput
                        disabled    
                        id="input-example"
                        name="input-name"
                        placeholder={0}
                        decimalSeparator="."
                        defaultValue={0}
                        value={custData.tarif_internet}
                        />
                        <label>Kuantitas Addon (Internet):</label>
                        <CurrencyInput
                        id="input-example"
                        name="input-name"
                        placeholder={qtyAddon}
                        value={qtyAddon}
                        onValueChange={(e) => {checkNum(e, (setQtyAddon))}}
                        />
                        <label>Diskon Addon: </label>
                        <CurrencyInput
                        id="input-example"
                        name="input-name"
                        decimalSeparator="."
                        placeholder={0}
                        value={discountAddon}
                        onValueChange={(e) => {checkNum(e, (setDiscountAddon))}}
                        />
                        <label>Total Addon: </label>
                        <CurrencyInput
                        disabled    
                        id="input-example"
                        name="input-name"
                        placeholder={0}
                        decimalSeparator="."
                        defaultValue={0}
                        value={Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*qtyAddon*Number(discountAddon/100))}
                        />
                        <h3>Diskon Tambahan</h3>
                        <CurrencyInput
                        id="input-example"
                        name="input-name"
                        decimalSeparator="."
                        placeholder={0}
                        value={discountPlus}
                        onValueChange={(e) => {checkNum(e, (setDiscountPlus))}}
                        />

                        <h3>Sub Total: </h3>
                        <CurrencyInput
                        disabled    
                        id="input-example"
                        name="input-name"
                        placeholder={0}
                        decimalSeparator="."
                        defaultValue={0}
                        onValueChange={(e) => (setSubTotal(e)) }
                        value={Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discount/100))-Number(discountPlus)+Number(custData.tarif_internet*qtyAddon)-Number(custData.tarif_internet*qtyAddon*Number(discountAddon/100))}
                        />
                        
                        <h3>PPN: 11%</h3>
                        <div className="check-group"  >
                        <input onChange={(e) => {checkPpnHandler(e.target.checked)}} type="checkbox" className="ppn-check" style={{width: "60px"}} />
                        <label>Ceklist PPN</label>
                        </div>
                        <CurrencyInput
                        disabled    
                        id="input-example"
                        name="input-name"
                        decimalSeparator="."
                        value={valuePPnHandler()}
                        />
                        <h3>Denda</h3>
                        
                        <CurrencyInput
                            id="input-example"
                            name="input-name"
                            placeholder={0}
                            decimalSeparator="."
                            value={penalty}
                            onValueChange={(e) => {checkNum(e, (setPenalty))}}
                            />

                        <h3>Tgl Jatuh Tempo:</h3>
                        <input value={tgl_jatuhtempo} onChange={(e) => {setTglJatuhtempo(e.target.value)}} type = "date" />
                        
                        <h3>Grand Total: </h3>
                        <CurrencyInput
                        disabled    
                        id="input-example"
                        name="input-name"
                        placeholder={0}
                        decimalSeparator="."
                        defaultValue={0}
                        value={grandTotalHandler()}
                        />
                        {/* Number(custData.nominal*qty)-Number(custData.nominal*qty*Number(discount/100))+Number(penalty)-Number(discountPlus)+Number(addOn*qtyAddon)-Number(addOn*qtyAddon*Number(discountAddon/100)) */}
                        {/* Number(addOn*qtyAddon)-Number(addOn*qtyAddon*Number(discountAddon/100)) */}
                        {/* (custData.nominal*discount/100) */}
                        {/* +Number(addOn*qtyAddon) */}
                    {/* <input disabled type="number"  value={(parseInt(custData.nominal*qty+penalty-discount)).toLocaleString('IN')} /> */}
                    <div className = "form-button" > 
                    <button onClick={saveNewInvoice} className = "profile-save btn ">Simpan</button>
                    <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                    </div>
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
    )

}

export default NewInvoice;