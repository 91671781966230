import React, { useEffect, useState } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import axios from "axios";
import { baseUrl } from "../../Config/URL";

const Kwitansi = () => {
    const [paidList, setPaidList] = useState([])

    useEffect(() => {
        getDataPaid()
    },[])
    
    const getDataPaid = () => {
        axios.get(baseUrl+'getpaidinvoice')
        .then(res => {
            console.log(res.data);
            setPaidList(res.data)
        })
    }

    const navigate = useNavigate()

    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        
      }

      const generateRef = (e, cond) => {


        let id = 0
        let tglInit = new Date()
        let tgl = tglInit.getMonth(e.created_at)+1
        let tglRoman = ""
        let year = tglInit.getFullYear()
  
        if(e.id_invoice < 10){
          id = "00"+e.id_invoice
        }else if(e.id_invoice >= 10 && e.id_invoice < 100){
          id = "0"+e.id_invoice
        }else{
          id = e.id_invoice
        }
  
  
        switch (tgl) {
          case 1:
            tglRoman = "I"
            break;
          case 2:
              tglRoman = "II"
            break;
          case 3:
      tglRoman = "III";
            break;
          case 4:
      tglRoman = "IV";
            break;
          case 5:
  tglRoman = "V";
            break;
          case 6:
    tglRoman = "VI";
            break;
          case 7:
    tglRoman = "VII";
            break;
          case 8:
          tglRoman = "VIII";
            break;
          case 9:
              tglRoman = "IX";
            break;
          case 10:
          tglRoman = "X";
            break;
          case 11:
            tglRoman = "XI";
            break;
          case 12:
            tglRoman = "XII";
            break;
      }
  
        // console.log(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year);
        // console.log(dataInvoice[0].id_invoice);
        // setRefrence(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year)
        let refrenceGen = ""
        if(cond === "inv"){
            refrenceGen = id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year
        }else if(cond === "kwt"){
            refrenceGen = id+'/'+'GGI-KWT/IPL-IT/MR/'+tglRoman+'/'+year
        }
        return refrenceGen
  
      }

    return(
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/invoice`)}} />
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    <section className="list-antrian" >
                    <h1>Invoice Paid</h1>
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th>No.</th>
                            <th>No. Invoice</th>
                            <th>No. Kwitansi</th>
                            <th>Nama</th>
                            <th>Jatuh Tempo</th>
                            <th>Tanggal Bayar</th>
                            <th>No.Unit</th>
                            <th>IPL</th>
                            <th>Kuantitas IPL</th>
                            <th>Harga Internet</th>
                            <th>Kuantitas Internet</th>
                            <th>Diskon</th>
                            <th>Denda</th>
                            <th>PPN</th>
                            <th>Grand Total</th>
                            <th>Tanggal Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paidList.map((item, index) => {
                                return(
                                    <>
                                        <tr onClick={() => {navigate('/cetakkwitansi', {state:item})}}>
                                            <td>{index+1}</td>
                                            <td>{generateRef(item, "inv")}</td>
                                            <td>{generateRef(item, "kwt")}</td>
                                            <td>{item.nama}</td>
                                            <td>{item.date_due}</td>
                                            <td>{item.paid_date}</td>
                                            <td>{item.no_unit}</td>
                                            <td>Rp.{item.ipl_invoice.toLocaleString('IN')}</td>
                                            <td>{item.kuantitas_ipl} Bulan</td>
                                            <td>Rp.{item.internet_invoice.toLocaleString('IN')}</td>
                                            <td>{item.kuantitas_internet} Bulan</td>
                                            <td>Rp.{item.potongan_tambahan.toLocaleString('IN')}</td>
                                            <td>Rp.{item.penalty_invoice.toLocaleString('IN')}</td>
                                            <td>Rp.{item.ppn_invoice.toLocaleString('IN')}</td>
                                            <td>Rp.{item.grandtotal_invoice.toLocaleString('IN')}</td>
                                            <td>{item.date_full}</td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>



                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Detail Tagihan</h1>
                  <div className = "profile-editForm" >
                    
                    <label>Harga :  </label>
                    <input type="number"   />
                    <label>Kuantitas: </label>
                    <input type="number"  />
                    <label>Denda: </label>
                    <input type="number"  />
                    <label>Paket: </label>
                    <input type="text"/>
                    <div className = "form-button" > 
                    <button className = "profile-save btn "   >Paid</button>
                    <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                    </div>
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
    )

}

export default Kwitansi;