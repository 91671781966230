import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Swal from "sweetalert2";

const AllCustomer = () => {
    const [dataAllCustomer, setDataAllCustomer] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        getAllCustomer()
    },[])

    // useEffect(() => {
    //     loading2(isLoading)
    // })

    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        
      }
    
    const getAllCustomer = () => {
        axios.get(baseUrl+"getalldatacustomer")
        .then(res => {
            console.log(res.data);
            setDataAllCustomer(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    

    // const loading2 = () => {
    //     if(isLoading){
    //         return(
    //             Swal.fire({
    //                 icon: "info",
    //                 title: "Loading",
    //                 text: "Please Wait",
    //                 showConfirmButton: false,
    //                 showCancelButton: false,
    //                 allowEnterKey: false,
    //                 allowOutsideClick: false

                    
    //             })
    //         )
    //     }else{return null}
    // }


    

    return(
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/sales`)}} />
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    <section className="list-antrian" >
                    <h1>All Customer</h1>
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th>No.</th>
                            <th>Nama</th>
                            <th>No.Hp</th>
                            <th>Email</th>
                            <th>No. Unit</th>
                            <th>Tipe Bangunan</th>
                            <th>Luas Tanah</th>
                            <th>Luas Bangunan</th>
                            </tr>
                        </thead>
                        <tbody>

                            {dataAllCustomer.map((item, index) => {
                                return(
                                    <>
                                       <tr>
                                            <td>{index+1}</td>
                                            <td>{item.nama}</td>
                                            <td>{item.no_hp1}</td>
                                            <td>{item.email}</td>
                                            <td>{item.no_unit}</td>
                                            <td>{item.tipe_bangunan}</td>
                                            <td>{item.luas_tanah} m<sup>2</sup></td>
                                            <td>{item.luas_bangunan} m<sup>2</sup></td>
                                        </tr> 
                                    </>
                                )
                            })}

                            {/* <tr onClick={() => {openModalHandler("1")}}>
                                <td>1</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr> */}

                        </tbody>
                    </table>



                    {/* MODAL START */}


                    {/* <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Detail BAST Untuk: {custName}</h1>
                  <div className = "profile-editForm" >
                    
                    <label>No. Bast :  </label>
                    <input type="text" value={bastNo} onChange={(e) => {setBastNo(e.target.value)}}   />
                    <label>Tgl Bast :  </label>
                    <input type="date" value={bastDate} onChange={(e) => {setBastDate(e.target.value)}}   />
                    
                    <div className = "form-button" > 
                    <button className = "profile-save btn" onClick={saveDataBast} >Simpan</button>
                    <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Keluar</button>
                    </div>
                    <button className="cancel-btn" >Pembatalan</button>
                  </div>
                </div>
        
              </div> */}


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
    )

}

export default AllCustomer;