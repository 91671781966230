import React, { useEffect, useRef, useState } from "react";
import {useNavigate, useLocation} from "react-router-dom"
import {
    FaArrowLeft,
    FaPrint
} from 'react-icons/fa'
import {useReactToPrint} from 'react-to-print'
import Swal from "sweetalert2";
import angkaTerbilang from '@develoka/angka-terbilang-js';


import './CetakKwitansi.css'


const CetakKwitansi = () => {
    const [dataKwt, setDataKwt] = useState({})
    const [grandtotal_invoice, setGrandTotal] = useState(0)
    const [subtotal, setSubtotal] = useState(0)
    const [potongan_tambahan, setPotonganTambahan] = useState(0)
    const [potongan_addon, setPotonganAddon] = useState(0)
    const [potongan_ipl, setPotonganIpl] = useState(0)
    const [total_ipl, setTotalIpl] = useState(0)
    const [total_addon, setTotalAddon] = useState(0)
    const [penalty, setPenalty] = useState(0)
    const [ppn_invoice, setPpnInvoice] = useState(0)
    const [nominalInvoice, setNominalInvoice] = useState(0)
    const [addOnInvoice, setAddonInvoice] = useState(0)


    const componentRef = useRef()
    const location = useLocation()

    useEffect(() => {
        const {state} = location
        setDataKwt(state)
        setPenalty(state.penalty_invoice)
        setPotonganIpl(state.potongan_ipl)
        setTotalIpl(state.total_ipl)
        setTotalAddon(state.total_internet)
        setPotonganTambahan(state.potongan_tambahan)
        setSubtotal(state.subtotal)
        setPpnInvoice(state.ppn_invoice)
        setGrandTotal(state.grandtotal_invoice)
        setNominalInvoice(state.ipl_invoice)
        setAddonInvoice(state.internet_invoice)

        console.log(state);
    }, [])

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "INVOICE Mazenta_",
        onAfterPrint: () => {
            Swal.fire({
                title: 'Success',
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              })
        }
    })
    const navigate = useNavigate()

    const generateRef = (e, cond) => {


        let id = 0
        let tglInit = new Date()
        let tgl = tglInit.getMonth(e.created_at)+1
        let tglRoman = ""
        let year = tglInit.getFullYear()
  
        if(e.id_invoice < 10){
          id = "00"+e.id_invoice
        }else if(e.id_invoice >= 10 && e.id_invoice < 100){
          id = "0"+e.id_invoice
        }else{
          id = e.id_invoice
        }
  
  
        switch (tgl) {
          case 1:
            tglRoman = "I"
            break;
          case 2:
              tglRoman = "II"
            break;
          case 3:
      tglRoman = "III";
            break;
          case 4:
      tglRoman = "IV";
            break;
          case 5:
  tglRoman = "V";
            break;
          case 6:
    tglRoman = "VI";
            break;
          case 7:
    tglRoman = "VII";
            break;
          case 8:
          tglRoman = "VIII";
            break;
          case 9:
              tglRoman = "IX";
            break;
          case 10:
          tglRoman = "X";
            break;
          case 11:
            tglRoman = "XI";
            break;
          case 12:
            tglRoman = "XII";
            break;
      }
  
        // console.log(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year);
        // console.log(dataInvoice[0].id_invoice);
        // setRefrence(id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year)
        let refrenceGen = ""
        if(cond === "inv"){
            refrenceGen = id+'/'+'GGI-INV/MR/'+tglRoman+'/'+year
        }else if(cond === "kwt"){
            refrenceGen = id+'/'+'GGI-KWT/IPL-IT/MR/'+tglRoman+'/'+year
        }
        return refrenceGen
  
      }

    return(
        <main id='newcustomer-container' >

            
            <form>
            <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/kwitansi")}} />
            
            <div className='cetak-button-container' onClick={printHandler} >
                <FaPrint className='cetak-button' size={30} onClick={printHandler}/>
                <text>Print</text>
            </div>
            {/* <text className='cetak-button' onClick={printHandler} >Print</text> */}
            <section ref={componentRef} className="cetak" id="cetak-container">

                <div className="kwitansi-head">
                    
                    <section  className = "head-address">
                        <h3>PT. GRIYA GARDENIA INDAH</h3>
                        <p>BAKTI KARYA RAYA, KELURAHAN/DESA. SAWAH BARU, KECAMATAN. CIPUTAT </p>
                        <p>TANGERANG BANTEN 15413</p>
                        <p>TELP. +62 21 2222 0080 FAX. +62 21 2222 0081</p>
                    </section>

                    <section className="desc-nofren">
                        
                        <table>
                            <tr>
                                <td>No</td>
                                <td>: {generateRef(dataKwt, "kwt")}</td>
                            </tr>
                            <tr>
                                <td>Blok/No.Unit</td>
                                <td>: {dataKwt.no_unit}</td>
                            </tr>
                            <tr>
                                <td>Tanggal</td>
                                <td>: {dataKwt.paid_date}</td>
                            </tr>
                        </table>

                    </section>

                    

                </div>

                <div className="title-kwitansi">
                        <h3>KWITANSI</h3>
                </div>

                <div className="hand-over" >

                    <table>
                        <tr>
                            <td>Telah Terima Dari</td>
                            <td>: {dataKwt.nama}</td>
                        </tr>
                        <tr>
                            <td>Sejumalah Uang</td>
                            <td>: Rp.{grandtotal_invoice.toLocaleString('IN')}</td>
                        </tr>
                                
                    </table>

                </div>

                <div className='header-desc header-desckwt' >

                    <text className='no'>No.</text>
                    <text className='keterangan'>Keterangan</text>
                    <text>Jumlah</text>

                </div>
                
                <div className="container-body">
                    
                    <div className='body-desc body-desckwt' >

                    <text className='no'>1</text>
                    <text className='keterangan'><p>Iuran Pemeliharaan Lingkungan(IPL) (Rp.{nominalInvoice.toLocaleString('IN')} x {dataKwt.kuantitas_ipl} bulan)</p></text>
                    <text><p>Rp.</p>{total_ipl.toLocaleString('IN')}</text>

                </div>
                <div className='body-desc body-desckwt' >

                    <text className='no asd'>2</text>
                    <text className='keterangan asd'><p>Internet Speed {dataKwt.speed_internet} Mbps (Rp.{addOnInvoice.toLocaleString('IN')}) x {dataKwt.kuantitas_internet} bulan</p></text>
                    <text><p>Rp.</p>{total_addon.toLocaleString('IN')}</text>

                </div>

                </div>

                <div className='total' >

                    {/* <div className='null' >
                        
                    </div> */}
                    
                    <div className='desc-total' >
                        <h3>Diskon : </h3>
                        <h3>Sub Total :</h3>
                        <h3>PPN :</h3>
                        <h3>Denda :</h3>
                        <h3 className = 'grand' >GRAND TOTAL</h3>
                    </div>

                    <div className='total-nominal kwt' >
                        <h3>Rp.<span>{(potongan_addon+potongan_ipl+potongan_tambahan).toLocaleString('IN')}</span></h3>
                        <h3>Rp.<span>{subtotal.toLocaleString('IN')}</span></h3>
                        <h3>Rp.<span>{ppn_invoice.toLocaleString('IN')}</span></h3>
                        <h3>Rp.<span>{penalty.toLocaleString('IN')}</span></h3>
                        <h3 className='grand' >Rp.<span>{grandtotal_invoice.toLocaleString('IN')}</span></h3>
                    </div>

                </div>

                <div className="kwitansi-foot" >
                    
                    <div className="terbilang" >
                        <h4>Terbilang : </h4>
                        <p>{angkaTerbilang(grandtotal_invoice)} rupiah</p>
                    </div>

                    <div className="sign">
                        <p>Diberikan oleh :</p>
                        <div className="sign-name">
                            <p>Rudy Susanto</p>
                            <h4>Finance & Accounting Manager</h4>
                        </div>
                    </div>

                </div>

                

            </section>
            
        </form>

            
        </main>
    )

}

export default CetakKwitansi;