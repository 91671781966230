import {IoMdConstruct} from 'react-icons/io'
import {MdDoNotDisturb} from 'react-icons/md'


export const Construct = () => {
    return(
        <div style={{
            width: "100%", 
            marginTop: "100px", 
            display:"flex", 
            flexDirection:"column",
            justifyContent: "center",
            alignItems: "center",
            border: "solid 1px black"
            }}>
                <h2>Sorry</h2>
        <text style={{fontSize: "30px"}} >This Page Still Under Construction</text>
        <IoMdConstruct size={90}/>
    </div>
    )
}

export const Denied = () => {
    return(
        <div style={{
            width: "90%", 
            marginTop: "100px", 
            display:"flex", 
            flexDirection:"column",
            justifyContent: "center",
            alignItems: "center",
            border: "solid 1px black"
            }}>
                <h2>Access Denied</h2>
        <text style={{fontSize: "30px"}} >Please contact your supervisor to access this page! </text>
        <MdDoNotDisturb size={90}/>
    </div>
    )
}