import Swal from "sweetalert2"

export const loadingHandler = (e) => {
    if(e){
        return(
            Swal.fire({
                icon: "info",
                title: "Loading",
                text: "Please Wait",
                showConfirmButton: false,
                showCancelButton: false,
                allowEnterKey: false,
                allowOutsideClick: false
            })
        )
    }
    
}