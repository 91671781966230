import React, { useEffect, useState } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import {baseUrl} from '../../Config/URL'



const DaftarIPL = () => {
    const [dataHasBast, setDataHasBast] = useState([])

    useEffect(() => {
        getDataHasBast()
    },[])

    const navigate = useNavigate()

    const getDataHasBast = () => {
        axios.get(baseUrl+"getdatahasbast")
        .then(res => {
            console.log(res.data);
            setDataHasBast(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    return(

        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/invoice`)}} />
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    <section className="list-antrian" >
                    <h1>Input IPL</h1>
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th>No.</th>
                            <th>Nama</th>
                            <th>No. Unit</th>
                            <th>Tipe Bangunan</th>
                            <th>Email</th>
                            <th>No. Hp</th>
                            <th>No. BAST</th>
                            <th>Tanggal BAST</th>
                            </tr>
                        </thead>
                        <tbody>

                            {dataHasBast.map((item, index) => {
                                return(
                                    <>
                                        <tr onClick={()=>{navigate("/formipl",{state:item})}}>
                                            <td>{index+1}</td>
                                            <td>{item.nama}</td>
                                            <td>{item.no_unit}</td>
                                            <td>{item.tipe_bangunan}</td>
                                            <td>{item.alamat_email}</td>
                                            <td>{item.no_hp1}</td>
                                            <td>{item.no_bast}</td>
                                            <td>{item.date_full}</td>
                                        </tr>
                                    </>
                                )
                            })}

                            
                        </tbody>
                    </table>



                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Pilih Unit Untuk</h1>
                  <div className = "profile-editForm" >
                    
                    <label>Cluster: </label>
                    <select >
                      <option>Mazenta 1</option>
                      <option>Mazenta 2</option>
                    </select>
                    <label>Unit: </label>
                    <select >
                      <option>001</option>
                      <option>002</option>
                    </select>
                    <div className = "form-button" > 
                    <button className = "profile-save btn "  >Simpan</button>
                    <button className = "profile-cancel  btn" id = "profile-cancel">Batalkan</button>
                    </div>
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>

    )

}

export default DaftarIPL