import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    FaArrowLeft
} from 'react-icons/fa'
import axios from "axios";
import { baseUrl } from "../../Config/URL";
import Swal from "sweetalert2";
import {loadingHandler} from "../../components/Loading"
import { Construct } from "../../components/Constuct";



const NewUser = () => {
    const [allRole, setAllRole] = useState([])
    const [nama, setNama] = useState("")
    const [email, setEmail] = useState("")
    const [no_telp, setNoTelp] = useState("")
    const [password, setPassword] = useState("")
    const [id_role, setIdRole] = useState("")
    const [isLoading, setIsLoading] = useState(false)


    const location = useLocation()
    const navigate = useNavigate()
    const {state} = location

    useEffect(() => {
        getAllROle()
    },[])

    useEffect(() => {
        loadingHandler(isLoading)
    })

    const getAllROle = () => {
        axios.get(baseUrl+'getallrole')
        .then(res => {
            setAllRole(res.data)
        })
    }

    const saveNewUser = (e) => {
        e.preventDefault()
        setIsLoading(true)
        axios.post(baseUrl+'addnewuser', {
            nama,
            email, 
            no_telp, 
            password, 
            id_role
        })
        .then(res => {
            setIsLoading(false)
            console.log(res.data);
            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)
                    setNama("")
                    setEmail("")
                    setNoTelp("")
                    setPassword("")
                    setIdRole("")
                }
              })
        }).catch(err => {
            console.log(err);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    setIsLoading(false)

                }
              })
        })

    }

    if(state.condition === "newuser"){
        return(
            <main id='newcustomer-container' >
                <form>
                <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/setting")}} />
                
                <h1>New User</h1>
                
                <div class="row">
                    <div class="column">
                        <label for="name">Nama: </label>
                        <input value={nama} onChange={(e) => {setNama(e.target.value)}}  type="text" id="no-nup" placeholder="Nama"/>
                    </div>
                    <div class="column">
                        <label for="unitcode">Email: </label>
                        <input value={email} onChange={(e) => {setEmail(e.target.value)}}  type="text" id="no-cusomer" placeholder="Email"/>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="name">No. Telp: </label>
                        <input value={no_telp} onChange={(e) => {setNoTelp(e.target.value)}} type="number" id="no-nup" placeholder="No. Telp"/>
                    </div>
                    <div class="column">
                        <label for="unitcode">Password: </label>
                        <input value={password} onChange={(e) => {setPassword(e.target.value)}}  type="password" id="no-cusomer" placeholder="Password"/>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="name">Role: </label>
                        <select onChange={(e) => {setIdRole(e.target.value)}} >
                            <option value="" >--Select Role--</option>
                            {allRole.map((item, index) => {
                                return(
                                    <>
                                    
                                        <option value={item.id}>{item.role_name}</option>
    
                                    </>
                                )
                            })}
                        </select>
                    </div>
                </div>
                
    
               
    
    
                
                
                <button onClick={(e) => {saveNewUser(e)}} >Submit</button>
            </form>
            </main>
        )
    }else{
        return(
            <main id='newcustomer-container' >
                <form>
                <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/setting")}} />
                
                <h1>Edit User</h1>

                <Construct/>
                
                
                {/* <div class="row">
                    <div class="column">
                        <label for="name">Nama: </label>
                        <input onChange={(e) => {setNama(e.target.value)}}  type="text" id="no-nup" placeholder="Nama"/>
                    </div>
                    <div class="column">
                        <label for="unitcode">Email: </label>
                        <input onChange={(e) => {setEmail(e.target.value)}}  type="text" id="no-cusomer" placeholder="Email"/>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="name">No. Telp: </label>
                        <input onChange={(e) => {setNoTelp(e.target.value)}} type="number" id="no-nup" placeholder="No. Telp"/>
                    </div>
                    <div class="column">
                        <label for="unitcode">Password: </label>
                        <input onChange={(e) => {setPassword(e.target.value)}}  type="password" id="no-cusomer" placeholder="Password"/>
                    </div>
                </div>
                <div class="row">
                    <div class="column">
                        <label for="name">Role: </label>
                        <select onChange={(e) => {setIdRole(e.target.value)}} >
                            <option value="" >--Select Role--</option>
                            {allRole.map((item, index) => {
                                return(
                                    <>
                                    
                                        <option value={item.id}>{item.role_name}</option>
    
                                    </>
                                )
                            })}
                        </select>
                    </div>
                </div>
                
    
               
    
    
                
                
                <button onClick={(e) => {saveNewUser(e)}} >Submit</button> */}
            </form>
            </main>
        )
    }

    // return(
    //     <main id='newcustomer-container' >
    //         <form>
    //         <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate("/setting")}} />
            
    //         <h1>New User</h1>
            
    //         <div class="row">
    //             <div class="column">
    //                 <label for="name">Nama: </label>
    //                 <input onChange={(e) => {setNama(e.target.value)}}  type="text" id="no-nup" placeholder="Nama"/>
    //             </div>
    //             <div class="column">
    //                 <label for="unitcode">Email: </label>
    //                 <input onChange={(e) => {setEmail(e.target.value)}}  type="text" id="no-cusomer" placeholder="Email"/>
    //             </div>
    //         </div>
    //         <div class="row">
    //             <div class="column">
    //                 <label for="name">No. Telp: </label>
    //                 <input onChange={(e) => {setNoTelp(e.target.value)}} type="number" id="no-nup" placeholder="No. Telp"/>
    //             </div>
    //             <div class="column">
    //                 <label for="unitcode">Password: </label>
    //                 <input onChange={(e) => {setPassword(e.target.value)}}  type="password" id="no-cusomer" placeholder="Password"/>
    //             </div>
    //         </div>
    //         <div class="row">
    //             <div class="column">
    //                 <label for="name">Role: </label>
    //                 <select onChange={(e) => {setIdRole(e.target.value)}} >
    //                     <option value="" >--Select Role--</option>
    //                     {allRole.map((item, index) => {
    //                         return(
    //                             <>
                                
    //                                 <option value={item.id}>{item.role_name}</option>

    //                             </>
    //                         )
    //                     })}
    //                 </select>
    //             </div>
    //         </div>
            

           


            
            
    //         <button onClick={(e) => {saveNewUser(e)}} >Submit</button>
    //     </form>
    //     </main>
    // )

}

export default NewUser;