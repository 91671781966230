import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './pages/Dashbosrd/Dashboard';
import Invoice from './pages/Invoice/Invoice';
import Comment from './pages/Comment.jsx';
import Product from './pages/Product.jsx';
import ProductList from './pages/ProductList.jsx';
import NewCustomer from './pages/Sales/NewCustomer';
import Sales from './pages/Sales/Sales';
import Unit from './pages/Sales/Unit';
import Login from './pages/Login/Login';
import DaftarIPL from './pages/Invoice/DaftarIPL';
import FormDataIPL from './pages/Invoice/FormDaftarIPL';
import NewInvoice from './pages/Invoice/NewInvoice';
import Bast from './pages/Sales/Bast';
import IPLList from './pages/Invoice/IPLList';
import CetakIPL from './pages/Invoice/CetakIPL';
import DetailCetak from './pages/Invoice/DetailCetak';
import Unpaid from './pages/Invoice/Unpaid';
import Kwitansi from './pages/Invoice/Kwitansi';
import CetakKwitansi from './pages/Invoice/CetakKwitansi';
import AllCustomer from './pages/Sales/AllCustomer';
import Setting from './pages/Setting/Setting';
import NewUser from './pages/Setting/NewUser';
import SettingRate from './pages/Setting/SettingRate';

const App = () => {


  // const logout = () => {
  //   return(
  //     <BrowserRouter>
      
  //       <Routes>
          
  //         <Route path="/" element={<Login />} />
        
  //       </Routes>
      
  //   </BrowserRouter>
  //   )
  // }

  // const login = () => {

  //   return(

  //     <BrowserRouter>
  //     <Sidebar>
  //       <Routes>
  //         <Route path="/" element={<Dashboard />} />
  //         <Route path="/dashboard" element={<Dashboard />} />
  //         <Route path="/unit" element={<Unit />} />
  //         <Route path="/sales" element={<Sales/>} />
  //         <Route path="/newcustomer" element={<NewCustomer />} />
  //         <Route path="/comment" element={<Comment />} />
  //         <Route path="/invoice" element={<Invoice />} />
  //         <Route path="/product" element={<Product />} />
  //         <Route path="/productList" element={<ProductList />} />
  //       </Routes>
  //     </Sidebar>
  //   </BrowserRouter>

  //   )

  // }

  if (window.sessionStorage.getItem('TokenCHL') === null) {
    return(
      <BrowserRouter>
      
        <Routes>
          
          <Route path="/" element={<Login />} />
        
        </Routes>
      
    </BrowserRouter>
    )
    
  }else{
    return(

      <BrowserRouter>
      <Sidebar>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/unit" element={<Unit />} />
          <Route path="/sales" element={<Sales/>} />
          <Route path="/newcustomer" element={<NewCustomer />} />
          <Route path="/comment" element={<Comment />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/daftaripl" element={<DaftarIPL />} />
          <Route path="/product" element={<Product />} />
          <Route path="/productList" element={<ProductList />} />
          <Route path="/formipl" element={<FormDataIPL />} />
          <Route path="/newinvoice" element={<NewInvoice />} />
          <Route path="/cetakipl" element={<CetakIPL />} />
          <Route path="/unpaid" element={<Unpaid />} />
          <Route path="/bast" element={<Bast />} />
          <Route path="/ipllist" element={<IPLList />} />
          <Route path="/detailcetak" element={<DetailCetak />} />
          <Route path="/kwitansi" element={<Kwitansi />} />
          <Route path="/cetakkwitansi" element={<CetakKwitansi />} />
          <Route path="/allcustomer" element={<AllCustomer />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/newuser" element={<NewUser />} />
          <Route path="/editrate" element={<SettingRate />} />
        </Routes>
      </Sidebar>
    </BrowserRouter>

    )
  }


  
};

export default App;