import React, { useEffect, useState } from "react";
import './Setting.css'
import { useNavigate } from "react-router-dom";
import {Denied} from '../../components/Constuct'

const Setting = () => {
    const [storage, setStorage] = useState({})

    

    const navigate = useNavigate()
    useEffect(() => {
        const token = sessionStorage.getItem('TokenCHL')
        setStorage(JSON.parse(token))
    },[])

    // return(
    //     <main id="setting">
    //         <h1>setting</h1>

    //         <Denied/>

    //         {/* <section className="set-all" id="set-user">

    //         <h3>USER</h3>
            
    //         <ul className="list-set">
    //             <li onClick={() => {navigate('/newuser', {state: {condition: "newuser"}})}} >Add New User</li>
    //             <li onClick={() => {navigate('/newuser', {state: {condition: "edituser"}})}}>Edit User</li>
    //         </ul>

    //         </section>

    //         <section className="set-all" id="set-user">

    //         <h3>IPL/IT RATE</h3>
            
    //         <ul className="list-set">
    //             <li onClick={() => {navigate('/invoice')}} >Edit IPL</li>
    //             <li>Edit Internet</li>
    //         </ul>

    //         </section>

    //         <section className="set-all" id="set-user">

    //         <h3>CUSTOMER</h3>
            
    //         <ul className="list-set">
    //             <li onClick={() => {navigate('/invoice')}} >Edit Customer</li>
    //         </ul>

    //         </section> */}

    //     </main>
    // )
    
    console.log(storage);

    if(storage.id_role === 1 || storage.id_role === 2){
        return(
            <main id="setting">
                <h1>setting</h1>
    
                <section className="set-all" id="set-user">
    
                <h3>USER</h3>
                
                <ul className="list-set">
                    <li onClick={() => {navigate('/newuser', {state: {condition: "newuser"}})}} >Add New User</li>
                    <li onClick={() => {navigate('/newuser', {state: {condition: "edituser"}})}}>Edit User</li>
                </ul>
    
                </section>
    
                <section className="set-all" id="set-user">
    
                <h3>IPL/IT RATE</h3>
                
                <ul className="list-set">
                    <li onClick={() => {navigate('/editrate',{state: {condition: "IPLRate"}})}} >Edit IPL</li>
                    <li onClick={() => {navigate('/editrate',{state: {condition: "InternetRate"}})}} >Edit Internet</li>
                </ul>
    
                </section>
    
                <section className="set-all" id="set-user">
    
                <h3>CUSTOMER</h3>
                
                <ul className="list-set">
                    <li>Edit Customer</li>
                </ul>
    
                </section>
    
            </main>
        )
    }else if(storage.id_role === 3){
        return(
            <main id="setting">
                <h1>setting</h1>
    
                <Denied/>
    
            </main>
        )
    }

    

}

export default Setting;