import axios from "axios";
import React, { useState, useEffect } from "react";
import {
    FaArrowLeft
} from 'react-icons/fa'
import {useNavigate} from 'react-router-dom'
import { baseUrl } from "../../Config/URL";
import Swal from "sweetalert2";

const Bast = () => {
    const [bastNo, setBastNo] = useState("")
    const [bastDate, setBastDate] = useState("")
    const [dataNoBast, setDataNoBast] = useState([])
    const [custId, setCustId] = useState("")
    const [custName, setCustName] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        getDataNoBast()
    },[])

    useEffect(() => {
        loading2(isLoading)
    })

    const openModalHandler = (e) => {
        const modal = document.getElementById("profile-modal")
        modal.style.display = "flex"
        console.log(e);
        setCustId(e.id_customer)
        setCustName(e.nama)
        
      }
      
    const  closeModalHandler = () => {
        document.getElementById("profile-modal").style.display = "none"
        setBastDate("")
        setBastNo("")
      }
    
    const getDataNoBast = () => {
        axios.get(baseUrl+"getdatanobast")
        .then(res => {
            console.log(res.data);
            setDataNoBast(res.data)
        }).catch(err => {
            console.log(err);
        })
    }

    const saveDataBast = () => {
        setIsLoading(true)
        axios.post(baseUrl+"savebast",{
            no_bast: bastNo,
            tgl_bast: bastDate,
            id_customer: custId
        }).then(res => {
            setIsLoading(false)
            console.log(res);

            Swal.fire({
                title: 'Success',
                text: res.data.msg,
                icon: 'success',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
              }).then((result) => {
                if (result.isConfirmed) {
                    getDataNoBast()
                    closeModalHandler()
                    setIsLoading(false)
                }
              })
        }).catch(err => {
            setIsLoading(false)
            console.log(err);

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: err.response.data.msg,
                allowOutsideClick: false
              }).then((result) => {
                if(result.isConfirmed){
                    setIsLoading(false)
                }
              })
        })
    }

    const loading2 = () => {
        if(isLoading){
            return(
                Swal.fire({
                    icon: "info",
                    title: "Loading",
                    text: "Please Wait",
                    showConfirmButton: false,
                    showCancelButton: false,
                    allowEnterKey: false,
                    allowOutsideClick: false

                    
                })
            )
        }else{return null}
    }


    

    return(
        <main id="unit-main" >
                <div className="unit-container" >
                    <FaArrowLeft className='arrow-back' size={25} onClick={() => {navigate(`/sales`)}} />
                    <section className="search-group" >

                        <input type="search" placeholder="Cari No. Reservasi/Nama" className="search-box" />
                        <button className="search-button" >Cari</button>

                    </section>

                    <section className="list-antrian" >
                    <h1>Berita Acara Serah Terima</h1>
                        
                    <table class="greyGridTable">
                        <thead>
                            <tr>
                            <th>No.</th>
                            <th>Nama</th>
                            <th>No. Unit</th>
                            <th>Tipe Bangunan</th>
                            </tr>
                        </thead>
                        <tbody>

                            {dataNoBast.map((item, index) => {
                                return(
                                    <>
                                       <tr onClick={() => {openModalHandler(item)}}>
                                            <td>{index+1}</td>
                                            <td>{item.nama}</td>
                                            <td>{item.no_unit}</td>
                                            <td>{item.tipe_bangunan}</td>
                                        </tr> 
                                    </>
                                )
                            })}

                            {/* <tr onClick={() => {openModalHandler("1")}}>
                                <td>1</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>123</td>
                                <td>asd</td>
                            </tr> */}

                        </tbody>
                    </table>



                    {/* MODAL START */}


                    <div className = "profile-modal" id = "profile-modal" >
                <div className = "profile-modalContent" >
                  <h1>Detail BAST Untuk: {custName}</h1>
                  <div className = "profile-editForm" >
                    
                    <label>No. Bast :  </label>
                    <input type="text" value={bastNo} onChange={(e) => {setBastNo(e.target.value)}}   />
                    <label>No. Bast PP :  </label>
                    <input type="text"/>
                    <label>Tgl Bast :  </label>
                    <input type="date" value={bastDate} onChange={(e) => {setBastDate(e.target.value)}}   />
                    {/* <label>Kuantitas: </label>
                    <input type="number"  />
                    <label>Denda: </label>
                    <input type="number"  />
                    <label>Paket: </label>
                    <input type="text"/> */}
                    <div className = "form-button" > 
                    <button className = "profile-save btn" onClick={saveDataBast} >Simpan</button>
                    <button onClick={closeModalHandler} className = "profile-cancel  btn" id = "profile-cancel">Keluar</button>
                    </div>
                    <button className="cancel-btn" >Pembatalan</button>
                  </div>
                </div>
        
              </div>


                    {/* MODAL END */}



                    </section>
                </div>
            </main>
    )

}

export default Bast;